<template>
  <section class="page_404 px-md-0 px-4">
    <div class="container">
      <div class="row">
        <div class="gif">
          <div class="col-sm-10 col-sm-offset-1 text-center">
            <img src="../../assets/images/svg/404.svg" class="image-404" />
            <div class="contant_box_404">
              <h3 class="h2">OPS!</h3>

              <p>A página que você procura não está disponível!</p>

              <b-button @click="$router.go(-1)" variant="primary">
                Voltar
              </b-button>
              <br />
              <b-button to="/" variant="light" class="mt-2">
                Ir para o início
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.page_404 {
  padding: 40px 0;
  background: $bg-1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .gif {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.four_zero_four_bg {
  width: 100%;
  max-height: 404px;
  margin: 2em auto;
  object-fit: contain;
}
.image-404 {
  @media (min-width: $break-md) {
    height: 400px;
  }
}
</style>
